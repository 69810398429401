// var stickyHeader = false;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
var countUpStats = true;
// var countUpStatsDuration = 3000;
var subsiteHijackMainNav = ['northern-ireland', 'scotland', 'wales', 'psychology-and-therapy-hub', 'fasd-hub'];
var subsiteHijackMainLogoClickDestination = ["fasd-hub","psychology-and-therapy-hub"];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// Bespoke JS from old site (membership customisations etc.)
// SimonM 18.01.18 + 25.01.18 + 09.03.18 Changing appeals for Memberships

// Change title legend
if ($('body.donate[class*="membership"]').length == 1) {
  $('.yourDonation legend').text("Your payment");
  setTimeout(function(){
    $('.button.callToActionBtn.donate').text('Add payment details');
  }, 1000);
  $('.donationAmount').click(function(){
    $('.button.callToActionBtn.donate').text('Add payment details');
  })
  $('head title').text($('head title').text().replace('Donate - ', ''));
}

//"Display your name with your donation" will be unticked and hidden
$(
  ".donate.family-membership-monthly .formQuestion.displayName input:checkbox"
).prop("checked", false);
$(".donate.family-membership-monthly .formQuestion.displayName").hide();

$(
  ".donate.family-membership-yearly .formQuestion.displayName input:checkbox"
).prop("checked", false);
$(".donate.family-membership-yearly .formQuestion.displayName").hide();

$(
  ".donate.family-finding-membership-monthly .formQuestion.displayName input:checkbox"
).prop("checked", false);
$(".donate.family-finding-membership-monthly .formQuestion.displayName").hide();

$(
  ".donate.family-finding-membership-yearly .formQuestion.displayName input:checkbox"
).prop("checked", false);
$(".donate.family-finding-membership-yearly .formQuestion.displayName").hide();

$(".donate.donate-now .formQuestion.displayName input:checkbox").prop(
  "checked",
  false
);
$(".donate.donate-now .formQuestion.displayName").hide();

$(
  ".donate.family-membership-yearly-direct-debit .formQuestion.displayName input:checkbox"
).prop("checked", false);
$(
  ".donate.family-membership-yearly-direct-debit .formQuestion.displayName"
).hide();

$(
  ".donate.family-finding-membership-yearly-direct-debit .formQuestion.displayName input:checkbox"
).prop("checked", false);
$(
  ".donate.family-finding-membership-yearly-direct-debit .formQuestion.displayName"
).hide();

$(
  ".donate.professional-membership-yearly .formQuestion.displayName input:checkbox"
).prop("checked", false);
$(".donate.professional-membership-yearly .formQuestion.displayName").hide();

$(
  ".donate.professional-membership-yearly-direct-debit .formQuestion.displayName input:checkbox"
).prop("checked", false);
$(
  ".donate.professional-membership-yearly-direct-debit .formQuestion.displayName"
).hide();

$(
  ".donate.professional-membership-monthly .formQuestion.displayName input:checkbox"
).prop("checked", false);
$(".donate.professional-membership-monthly .formQuestion.displayName").hide();

// Pre-tick regular payment
$(
  ".donate.family-membership-monthly .formQuestion.donationFrequency input:checkbox"
).prop("checked", true);
$(
  ".donate.family-finding-membership-monthly .formQuestion.donationFrequency input:checkbox"
).prop("checked", true);

$(document).ready(function () {
  if (
    $("body").hasClass("professional-membership-yearly-direct-debit") ||
    $("body").hasClass("professional-membership-monthly")
  ) {
    PaymentManager.triggerPaymentMethodChange("direct-debit", "credit-card");
    $("div.donationFrequency").hide();
  }

  // Un-tick regular payment
  if ($("body").hasClass("professional-membership-yearly")) {
    PaymentManager.triggerPaymentMethodChange("credit-card", "direct-debit");
    $("div.donationFrequency").hide();
  }
});

// Change the DD period to yearly via jQuery!!
// BM DES-1401 12 April 2018

$(".donate.family-membership-yearly-direct-debit #donationPeriod").val(
  "Yearly"
);
// Keep only Monthly option
if ($("body").hasClass("family-finding-membership-monthly")) {
  var select = jQuery("#donationPeriod");
  var options = select.children();
  for (var i = 0; i <= options.length; i++) {
    var currentOption = jQuery(options[i]);
    if (currentOption.val() == "Monthly") {} else {
      currentOption.remove();
    }
  }
}

// ===========
// REDESIGN JS
// ===========

// Home features section 1 - "How we help"
$('.homeBox1, .homeBox2, .homeBox3').wrapAll('<div class="featureSection1">');
$('<h2>Our Community</h2>').prependTo('.featureSection1');

// Wrap subsite intro content - mockup sent, delete if unwanted
if ($('body').hasClass('subsite')) {
  $('.headerTextSubsite, .subsiteBody').wrapAll('<div class="subsiteIntro">');
}

// Clone share links below header
if (!$('body').hasClass('homepage') && !$('body').hasClass('faqsList')) {
  $('.shareWrapper').clone().insertAfter('.headerText .title');
  $('.headerText .shareWrapper').wrap('<div class="shareWrapperContainer">');
}